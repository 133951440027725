@import "../../scss/abstracts/variables";
@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/functions";

.about {
  padding-top: clamp(10px, size-desktop(250px), 250px);
  &__content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  &__info-wrapper {
    grid-area: 1 / 1 / 3 / 4;

    display: flex;
    flex-direction: column;
  }
  &__info-text {
    font-size: clamp(36px, size-desktop(64px), 64px);

    margin-right: size-desktop(20px);

    .line-wrapper {
      &:nth-child(1) {
        margin-left: clamp(150px, size-desktop(330px), 330px);
      }
    }
  }

  &__info-subtext {
    margin-top: auto;
    margin-bottom: auto;
    max-width: size-desktop(330px);

    color: $light-grey;
  }

  &__author-imgbox {
    position: relative;
    grid-area: 1 / 4 / 3 / 5;
    max-width: 330px;

    // height: size-desktop(467px) !important; // issue #4
    max-height: 467px;
  }
  &__author-imgBg {
    background-color: $white;
    height: 100%;
  }
  &__author-imgBl {
    height: 100%;
    overflow: hidden;
  }
  &__author-img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  &__author-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-area: 1 / 5 / 3 / 6;
    margin-left: clamp(10px, size-desktop(0px), 0px);
  }
  &__author-text {
    font-size: clamp(8px, size-desktop(18px), 18px);

    color: $white;
  }

  &__author-link {
    font-size: clamp(8px, size-desktop(18px), 18px);
    line-height: 21px;

    margin-top: 10px;

    transition: color 0.3s;

    &:hover {
      color: $light-grey;
    }
  }
  &__box {
    grid-area: 3 / 1 / 4 / 6;

    margin-top: clamp(10px, size-desktop(250px), 250px);
  }
  &__box-text {
    font-size: clamp(13px, size-desktop(32px), 32px);

    max-width: 60%;

    &:nth-child(2) {
      margin-left: 20%;
    }
    &:nth-child(3) {
      margin-left: 40%;
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
