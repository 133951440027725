@import "../../scss/abstracts/variables";
@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/functions";
.storys {
  padding-top: 171px;
  padding-bottom: clamp(10px, size-desktop(250px), 250px);
  &__back-link {
    display: block;

    margin-bottom: clamp(10px, size-desktop(120px), 120px);

    transition: color 0.3s;

    color: $light-grey;

    &:hover {
      color: $white;
    }
  }
  &__title {
    margin-bottom: clamp(10px, size-desktop(200px), 200px);
  }
  &__filter {
    position: relative;

    display: flex;
    justify-content: space-between;

    padding: clamp(10px, size-desktop(50px), 50px) 0;
    margin-bottom: clamp(10px, size-desktop(80px), 80px);

    &:before,
    &:after {
      position: absolute;
      left: 0;

      height: 1px;
      width: 100%;

      content: "";

      background-color: $light-grey;
    }
    &:before {
      top: 0;
    }
    &:after {
      bottom: 0;
    }
  }

  &__filter-list {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    margin-right: 50px;
  }
  &__filter-item {
    font-size: clamp(13px, size-desktop(18px), 18px);

    // cursor: pointer;;
    transition: color 0.3s;

    color: $light-grey;

    &:not(:last-child) {
      margin-right: 20px;
    }

    &:hover {
      color: $white;
    }
  }
  &__filter-select {
    font-size: clamp(13px, size-desktop(18px), 18px);

    max-width: 330px;
    width: 100%;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: clamp(10px, size-desktop(20px), 20px);
  }

  &__item {
    display: flex;
    flex-direction: column;

    width: 100%;

    background-color: $grey;
    box-shadow: 0 0 0 2px $light-grey;

    &:nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
    }
    &:nth-child(2) {
      grid-area: 1 / 2 / 2 / 4;
    }
    &:nth-child(3) {
      grid-area: 1 / 5 / 2 / 6;
    }
    &:nth-child(4) {
      grid-area: 2 / 3 / 3 / 5;
    }
    &:nth-child(5) {
      grid-area: 3 / 1 / 4 / 3;
    }
    &:nth-child(6) {
      grid-area: 3 / 4 / 4 / 5;
    }
    &:nth-child(7) {
      grid-area: 4 / 1 / 5 / 2;
    }
    &:nth-child(8) {
      grid-area: 4 / 3 / 5 / 5;
    }
    &:nth-child(9) {
      grid-area: 4 / 5 / 5 / 6;
    }
    &:nth-child(10) {
      grid-area: 5 / 2 / 6 / 3;
    }
    &:nth-child(11) {
      grid-area: 5 / 4 / 6 / 6;
    }
    &:nth-child(12) {
      grid-area: 6 / 2 / 7 / 4;
    }
    &:nth-child(13) {
      grid-area: 7 / 1 / 8 / 2;
    }
    &:nth-child(14) {
      grid-area: 7 / 3 / 8 / 5;
    }
    &:nth-child(15) {
      grid-area: 7 / 5 / 8 / 6;
    }
    &:nth-child(16) {
      grid-area: 8 / 1 / 9 / 3;
    }
    &:nth-child(17) {
      grid-area: 8 / 4 / 9 / 5;
    }
    &:nth-child(18) {
      grid-area: 9 / 3 / 10 / 5;
    }
    &:nth-child(19) {
      grid-area: 10 / 1 / 11 / 2;
    }
    &:nth-child(20) {
      grid-area: 10 / 2 / 11 / 4;
    }
    &:nth-child(21) {
      grid-area: 10 / 5 / 11 / 6;
    }
    &:nth-child(22) {
      grid-area: 11 / 2 / 12 / 3;
    }
    &:nth-child(23) {
      grid-area: 11 / 4 / 12 / 6;
    }
    &:nth-child(24) {
      grid-area: 12 / 2 / 13 / 4;
    }
  }
  &__item--big {
    .storys__item-img {
      height: clamp(13px, size-desktop(275px), 275px);
    }
    .storys__item-text {
      -webkit-line-clamp: 2;
    }
    .storys__item-title {
      -webkit-line-clamp: 1;
    }
  }
  &__item-content {
    display: flex;
    flex-direction: column;
    flex: 1;

    padding: 20px;
  }
  &__item-img {
    height: clamp(13px, size-desktop(200px), 200px);
  }
  &__item-tag {
    display: inline-block;

    margin-bottom: 20px;

    color: $light-grey;
  }
  &__item-body {
    flex-grow: 1;
  }
  &__item-title {
    line-height: 1.4;

    margin-bottom: 20px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &__item-text {
    margin-bottom: 20px;

    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &__item-link {
    min-width: 120px;
    margin-left: auto;
  }
}
