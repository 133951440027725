.App {
  display: flex;
  flex-direction: column;

  height: 100vh;

  cursor: none;
}

.cursor,
.aura {
  position: absolute;
  border-radius: 100%;
  transition: 0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -1.27, 0.3, 2.33) opacity, background-color 0.3s;
  user-select: none;
  pointer-events: none;
  z-index: 170;
}
.cursor {
  width: 8px;
  height: 8px;
  background-color: #fff;
  transform: scale(1);
  &.active {
    transform: scale(0.001);
  }
}

.aura {
  width: 46px;
  height: 46px;
  border: 1px solid rgba(#fff, 0.2);
  background-color: rgba(#fff, 0.027);
  transform: translate(5px, 5px);
  transition: background-color 0.3s;
  &.active {
    background-color: rgba(#fff, 0.5);

    border: 1.2px solid rgba(#fff, 0.5);
    transform: scale(1.3);
  }
}
.aura.hidden,
.cursor.hidden {
  transform: scale(0.001);
}
