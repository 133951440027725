@import "../../scss/abstracts/variables";
@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/functions";

.blog-list {
  &__list {
    overflow: hidden;
  }
  &__item {
    position: relative;

    overflow: hidden;
  }
  &__item-wrapper {
    display: grid;
    grid-gap: 15px;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    padding: size-desktop(30px) 0;

    transition: border 0.3s;

    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
    &:not(:first-child) {
      margin-top: -1px;
    }

    &:hover {
      z-index: 100;

      border-color: $white;
      .blog-list__text .line-wrapper .line .word {
        color: $white;
      }
      .blog-list__date .line-wrapper .line .word {
        color: $white;
      }
    }
  }
  &__main-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    grid-column: 1 / 3;
  }
  &__name {
    font-size: clamp(20px, size-desktop(32px), 32px);

    margin-bottom: 10px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    text-overflow: ellipsis;
    .line-wrapper .line .word {
      color: $white;
    }
  }
  &__tags {
    display: flex;
  }
  &__tag {
    font-size: clamp(13px, size-desktop(18px), 18px);

    padding: size-desktop(10px) size-desktop(20px);

    text-transform: uppercase;

    border: $contour-white;
    border-radius: size-desktop(100px);

    &:before {
      content: "# ";
    }
    &:not(:last-child) {
      margin-right: size-desktop(20px);
    }
  }
  &__text-wrapper {
    max-width: size-desktop(330px);
    width: 100%;
    // margin-right: size-desktop(20px);
  }
  &__text {
    font-size: clamp(13px, size-desktop(18px), 18px);

    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    transition: color 0.3s;

    color: $light-grey;
  }
  &__imgBox {
    // max-width: clamp(10px, size-desktop(330px), 330px);
    width: 100%;
    // height: clamp(10px, size-desktop(210px), 210px);
  }
  &__imgBg {
    background-color: $white;
    height: 100%;
  }
  &__imgBl {
    height: 100%;
    overflow: hidden;
  }
  &__img {
    object-fit: cover;
    height: 100%;
    // width: 100%;
  }
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;

    max-width: size-desktop(330px);
    width: 100%;
  }
  &__date {
    font-size: clamp(13px, size-desktop(18px), 18px);

    margin-top: size-desktop(-15px);

    transition: color 0.3s;

    color: $light-grey;
  }
  &__link {
    font-size: clamp(13px, size-desktop(18px), 18px);
    line-height: 21px;

    transition: color 0.3s;

    &:hover {
      color: $light-grey;
    }
  }
}
