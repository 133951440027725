@import "../../scss/abstracts/variables";
@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/functions";

.buy-form {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  z-index: 155;
  background-color: $dark-grey;
  background-image: url("../../../../public/images/bg2.png");
  flex-direction: column;
  transform: translateY(-100%);

  .container {
    display: flex;
    flex-direction: column;
  }
  &__wrapper {
    width: 100%;
    height: 100vh;
    z-index: 100;

    transform: translateX(100%);
    justify-content: center;
    display: flex;

    background-color: $super-light-grey;
    background-image: url("../../../../public/images/bg.png");
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 32px 0;
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
  }

  .close-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40px;
    height: 40px;
    position: relative;
    overflow: hidden;
    > div {
      position: relative;
    }
    &-block {
      width: 40px;
      height: 20px;
      position: relative;
      overflow: hidden;
      &:before,
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: calc(55% - 3px);
        display: block;
        width: 3px;
        height: 25px;
        transform-origin: bottom center;
        background: $grey;
        transition: all ease-out 280ms;
      }
      &:last-of-type {
        transform: rotate(180deg);
      }
    }
    .in {
      .close-button-block {
        &:before {
          transition-delay: 280ms;
          transform: translateX(22px) translateY(-22px) rotate(45deg);
        }
        &:after {
          transition-delay: 280ms;
          transform: translateX(-22px) translateY(-22px) rotate(-45deg);
        }
      }
    }
    .out {
      position: absolute;
      top: 0;
      left: 0;
      .close-button-block {
        &:before {
          transform: translateX(-5px) translateY(5px) rotate(45deg);
        }
        &:after {
          transform: translateX(5px) translateY(5px) rotate(-45deg);
        }
      }
    }
    &:hover {
      .in {
        .close-button-block {
          &:before {
            transform: translateX(-5px) translateY(5px) rotate(45deg);
          }
          &:after {
            transform: translateX(5px) translateY(5px) rotate(-45deg);
          }
        }
      }
      .out {
        .close-button-block {
          &:before {
            transform: translateX(-20px) translateY(20px) rotate(45deg);
          }
          &:after {
            transform: translateX(20px) translateY(20px) rotate(-45deg);
          }
        }
      }
    }
  }
  &__logo {
    opacity: 0;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  &__text {
    font-size: clamp(13px, size-desktop(18px), 18px);

    max-width: 20%;

    color: $light-grey;
    opacity: 0;
    p {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    span {
      font-weight: 700;
    }
  }
  &__title {
    margin-right: 20px;
  }
  &__title-wrapper {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }
  &__title-img {
    opacity: 0;
  }
  &__subtitle {
    padding-top: 10px;
    margin-bottom: size-desktop(60px);

    border-top: $contour-grey;

    transform: translateX(-100%);
  }
  &__form-box {
    max-width: 60%;
    width: 100%;

    overflow: hidden;
  }
  &__form {
    position: relative;

    font-size: 32px;

    display: flex;
    flex-direction: column;
  }
  &__input {
    padding: 10px 0;
    background-color: transparent !important;

    border-bottom: $contour-grey;

    transform: translateX(-100%);

    &--text {
      resize: none;
      overflow-y: auto;

      max-height: 250px;
      padding-right: 40px;

      &::-webkit-scrollbar {
        width: 28px;
      }
      &::-webkit-scrollbar-track {
        background-color: $super-light-grey;
        border: 1px solid $super-light-grey;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $dark-grey;
        border-left: 10px solid $super-light-grey;
        border-right: 10px solid $super-light-grey;
      }
      &::-webkit-scrollbar-button {
        background-color: $super-light-grey;
        height: 0px;
      }
    }
    &:not(:last-child) {
      // margin-bottom: size-desktop(35px);
      margin-bottom: 35px;
    }
    &:-webkit-autofill {
      background-color: transparent !important;
    }
  }
  &__label {
    position: absolute;
    right: 40px;
    // bottom: size-desktop(135px);
    bottom: 135px;

    width: 28px;
    height: 25px;

    // cursor: pointer;
    opacity: 0;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("../../../../public/images/icons/file.svg");
  }

  &__form-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__form-text {
    font-size: clamp(13px, size-desktop(18px), 18px);

    color: $light-grey;

    opacity: 0;
  }
  &__form-button {
    max-width: clamp(160px, size-desktop(330px), 330px);
    opacity: 0;
    .button__text {
      font-size: clamp(18px, size-desktop(36px), 36px) !important;
    }
    .button__span {
      background-color: $super-light-grey;
    }
  }

  .line {
    transform: translateY(300%) rotate(10deg);
  }
}
