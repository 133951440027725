@import "../../scss/abstracts/variables";
@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/functions";

.select-date {
  font-size: clamp(13px, size-desktop(18px), 18px) !important;

  display: flex;

  width: 100%;

  &__item {
    width: 100%;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
