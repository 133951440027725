@import "../../scss/abstracts/variables";
.button {
  position: relative;

  padding: 8px 0px;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  text-align: center;
  text-transform: uppercase;
  transition: border 5s, background-color 0.4s;

  border: $contour-dark-grey;
  border-radius: 100px;

  background-color: $white;

  &__text {
    font-size: clamp(13px, size-desktop(18px), 18px);
    white-space: nowrap;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    position: relative;
    z-index: 2;
    margin: auto;

    transition: color 0.6s;

    color: $dark-grey;
  }

  &__span {
    border-radius: 50%;
    background-color: $dark-grey;
    position: absolute;
    top: 5px;
    left: 10px;
    z-index: -1;
    animation: scale-down 0.6s forwards;
  }

  &.animate &__span {
    animation: scale-up 3s forwards;
  }

  &:hover & {
    &__text {
      color: $white;
    }
  }
  &:hover {
    transition: border 0.3s;
    border: $contour-white;
  }
  &--black {
    background-color: $dark-grey;
    border: $contour-white;

    &:hover {
      border: $contour-dark-grey;
    }
  }

  &--black & {
    &__span {
      background-color: $white;
    }

    &__text {
      color: $white;
    }
  }

  &--black:hover & {
    &__text {
      color: $dark-grey;
    }
  }
}

@keyframes scale-up {
  to {
    transform: scale(3000);
  }
}

@keyframes scale-down {
  from {
    transform: scale(3000);
  }
  to {
    transform: scale(0);
  }
}
