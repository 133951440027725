@import "../../scss/abstracts/variables";
@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/functions";

.menu-list {
  @include margin-left40;
  &__list {
    box-shadow: inset 0 size-desktop(1px) 0 rgba(113, 113, 113);
  }

  &__item {
    position: relative;

    overflow: hidden;

    transition: opacity 0.3;

    &:not(:first-child) {
      margin-top: -2px;
    }
    &:hover {
      z-index: 100;
      .menu-list__link {
        color: $white;
      }
      &:after,
      &:before {
        width: 100%;
      }
    }
    &:after,
    &:before {
      position: absolute;
      content: "";

      left: 0;
      width: 0%;
      height: size-desktop(3px);
      background-color: $white;
      transition: width 1s;
    }
    &:before {
      top: 0px;
    }
    &:after {
      bottom: 0px;
    }
  }
  &__link {
    position: relative;

    font-size: clamp(20px, size-desktop(36px), 36px);

    width: 100%;
    padding: clamp(10px, size-desktop(25px), 25px) 0;

    color: $light-grey;

    display: flex;
    justify-content: space-between;

    align-items: center;
  }
  &__link-wrapper {
    border-bottom: size-desktop(1px) solid $light-grey;
  }
  &__link-span {
    transition: color 0.3s;
  }
  &__link-svg {
    transition: color 0.3s;
  }
}
