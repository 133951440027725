@import "../../scss/abstracts/variables";
@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/functions";
.month-results {
  padding-top: 171px;
  padding-bottom: clamp(10px, size-desktop(250px), 250px);
  &__back-link {
    display: block;

    margin-bottom: clamp(10px, size-desktop(120px), 120px);

    color: $light-grey;
  }
  &__title {
    margin-bottom: clamp(10px, size-desktop(200px), 200px);
  }
  &__content {
    display: flex;
    justify-content: space-between;
  }
  &__span {
    font-size: clamp(13px, size-desktop(18px), 18px);

    display: block;

    width: 100%;
    padding-right: 20px;
    max-width: 515px;
  }
  &__body {
    max-width: 60%;
    width: 100%;
  }
  &__tables {
    display: flex;

    margin-bottom: 60px;
  }
  &__table {
    position: relative;

    height: 200px;

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;

      height: 1px;
      width: 80%;

      content: "";
      transform: translate(-50%, -50%);

      background-color: $light-grey;
    }

    &:nth-child(1) {
      max-width: 260px;
      margin-right: 120px;
    }

    &:nth-child(2) {
      max-width: 495px;
      &:before {
        width: 90%;
      }
    }
  }
  &__table-contents {
    display: grid;
    grid-template-rows: repeat(2, 1fr);

    &--2 {
      grid-template-columns: repeat(2, 1fr);
    }
    &--4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  &__table-content {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 20px 35px;

    &--is,
    &--s,
    &--r {
      &::after {
        position: absolute;
        bottom: 0;
        right: 0;

        width: 1px;
        height: 80%;

        content: "";

        background-color: $light-grey;
      }
    }
    &--ee,
    &--s2,
    &--e {
      &::after {
        position: absolute;
        top: 0;
        right: 0;

        width: 1px;
        height: 80%;

        content: "";

        background-color: $light-grey;
      }
    }
    &--r {
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;

        width: 1px;
        height: 80%;

        content: "";

        background-color: $light-grey;
      }
    }
    &--e {
      align-items: start;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 75px;

        content: "";
        background-color: $light-grey;
      }
    }
    &--is {
      padding-top: 0;
      padding-left: 0;
    }
    &--s2 {
      padding-bottom: 0;
      padding-left: 0;
    }
    &--s {
      padding-top: 0;
      padding-left: 0;
      max-width: 125px;
    }
    &--r {
      padding-top: 0;
      width: 150px;
    }
    &--e {
      padding-bottom: 0;
    }
    &--ee {
      padding-bottom: 0;
      padding-left: 0;
    }
  }
  &__body-text {
    font-size: clamp(13px, size-desktop(18px), 18px);
  }
  &__body-link {
    margin-top: 30px;
  }
}
