@import "../../scss/abstracts/variables";
@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/functions";

.swiper {
  width: 80%;
  height: 100%;

  margin-right: 0;
}
.archetypes-demo {
  &__stub {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 150;
  }
  &__slider-wrapper {
    position: relative;

    display: flex;
  }
  &__buttons-title {
    font-size: clamp(13px, size-desktop(18px), 18px);

    margin-bottom: size-desktop(60px);

    color: $light-grey;
  }
  &__button {
    position: relative;

    width: 42px;
    height: 42px;

    border: $contour-white;
    border-radius: 50%;

    transition: opacity 0.3s;

    &:hover {
      opacity: 0.3;
    }
    &:not(:last-child) {
      margin-right: 10px;
    }

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;

      height: 10px;
      width: 10px;

      content: "";
      transform: translate(-50%, -50%);

      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-image: url("../../../../public/images/icons/angle-right.svg");
    }
    &--prev {
      &:after {
        transform: rotate(180deg) translate(50%, 50%);
      }
    }
  }
  &__item-imgBox {
    width: clamp(10px, size-desktop(330px), 330px);
    height: clamp(10px, size-desktop(330px), 330px);
  }
  &__item-imgBg {
    background-color: $white;
    height: 100%;
  }
  &__item-imgBl {
    height: 100%;
    overflow: hidden;
  }
  &__item-img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
.swiper-slide {
  width: 100%;

  opacity: 0.6;

  .archetypes-demo {
    &__item-number {
      font-size: clamp(24px, size-desktop(32px), 32px);

      display: block;

      padding-bottom: size-desktop(20px);
      margin-bottom: size-desktop(30px);

      border-bottom: $contour-white;

      // transform: translateX(-100%);
    }
    &__item-title {
      font-size: clamp(18px, size-desktop(32px), 32px);

      margin-bottom: size-desktop(30px);
    }

    &__item-info {
      position: absolute;
      bottom: 0;

      display: flex;
      flex-direction: column;

      width: 100%;
      max-width: clamp(13px, size-desktop(680px), 680px);
      padding-left: clamp(10px, size-desktop(20px), 20px);

      transition: opacity 1.5s;
      transform: translateX(100%);

      opacity: 0;
      visibility: hidden;
    }
    &__item-wrapper {
      position: relative;
    }
    &__item-body {
      overflow: hidden;
    }
    &__item-text {
      font-size: clamp(13px, size-desktop(18px), 18px);

      margin-bottom: size-desktop(20px);

      width: clamp(10px, size-desktop(680px), 680px);
    }
    &__item-link {
      font-size: 18px;
      line-height: 21px;
      max-width: 180px;

      margin-left: auto;
      margin-right: size-desktop(161px);

      transition: color 0.3s;

      &:hover {
        color: $light-grey;
      }
    }
  }
}
.swiper-slide-active {
  width: size-desktop(680px) !important;
  max-width: 680px !important;
  opacity: 1;
  .archetypes-demo {
    &__item-imgBox {
      width: clamp(10px, size-desktop(680px), 680px);
      height: clamp(10px, size-desktop(680px), 680px);
    }
    &__item-img {
      width: 100%;
      height: 100%;
    }
    &__item-info {
      opacity: 1;
      visibility: visible;
    }
  }
}
