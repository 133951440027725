@import "../../scss/abstracts/variables";
@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/functions";

.select {
  font-size: clamp(13px, size-desktop(18px), 18px) !important;
  &__control {
    // cursor: pointer; !important;
    transition: border-color 0.3s;

    background-color: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    border-bottom: $contour-grey !important;
    &:hover {
      border-color: white !important;
    }

    cursor: none !important;
  }
  &__control--menu-is-open {
    border-color: white !important;
  }

  &__control--menu-is-open & {
    &__indicator {
      color: $white !important;
    }
    &__single-value {
      color: $white !important;
    }
    &__placeholder {
      color: $white !important;
    }
  }
  &__control:hover & {
    &__indicator {
      color: $white !important;
    }
    &__placeholder {
      color: $white !important;
    }
    &__single-value {
      color: $white !important;
    }
  }
  &__value-container {
    padding: 10px 20px !important;
  }
  &__input-container {
    margin: 0 !important;
    padding: 0 !important;
  }
  &__input {
    color: $white !important;
  }
  &__placeholder {
    color: $light-grey !important;
    transition: color 0.3s !important;
  }
  &__single-value {
    color: $light-grey !important;
    transition: color 0.3s !important;
  }
  &__indicator-separator {
    display: none !important;
  }

  &__indicator {
    padding: 10px 20px !important;

    transition: color 0.3s !important;

    color: $light-grey !important;
  }

  &__menu {
    z-index: 10 !important;

    padding: 10px !important;
    border-radius: 0 !important;
    background-color: $white !important;
    color: $dark-grey !important;
  }
  &__menu-list {
    @include scroll-style;

    max-height: 270px !important;
  }
  &__option {
    padding: 10px !important;
    cursor: none !important;
    // cursor: pointer; !important;

    &--is-focused {
      transition: color 0.3s, background-color 0.3s !important ;

      background-color: $dark-grey !important;
      color: $white !important;
    }
    &--is-selected {
      background-color: $dark-grey !important;
      color: $white !important;
    }
  }
}
