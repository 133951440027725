@import "../../scss/abstracts/variables";
@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/functions";

.results-list {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(10, 1fr);
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: clamp(10px, size-desktop(35px), 35px);
    &:nth-child(1) {
      grid-area: 1 / 4 / 2 / 5;
    }
    &:nth-child(2) {
      grid-area: 2 / 1 / 3 / 2;
    }
    &:nth-child(3) {
      grid-area: 2 / 3 / 3 / 4;
    }
    &:nth-child(4) {
      grid-area: 2 / 5 / 3 / 6;
    }
    &:nth-child(5) {
      grid-area: 2 / 7 / 3 / 8;
    }
    &:nth-child(6) {
      grid-area: 3 / 2 / 4 / 3;
    }
    &:nth-child(7) {
      grid-area: 3 / 4 / 4 / 5;
    }
    &:nth-child(8) {
      grid-area: 3 / 6 / 4 / 7;
    }
    &:nth-child(9) {
      grid-area: 4 / 3 / 5 / 4;
    }
    &:nth-child(10) {
      grid-area: 4 / 5 / 5 / 6;
    }
    &:nth-child(11) {
      grid-area: 4 / 7 / 5 / 8;
    }
    &:nth-child(12) {
      grid-area: 5 / 4 / 6 / 5;
    }
    &:nth-child(13) {
      grid-area: 5 / 7 / 6 / 8;
    }
    &:nth-child(14) {
      position: relative;

      grid-area: 6 / 4 / 7 / 5;

      &:after,
      &:before {
        position: absolute;

        width: clamp(10px, size-desktop(265px), 265px);
        height: clamp(10px, size-desktop(125px), 125px);

        content: "";

        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: url("../../../../public/images/decor/results-decor.svg");
      }
      &::before {
        top: clamp(-140px, size-desktop(-140px), -10px);
      }
      &:after {
        bottom: clamp(-140px, size-desktop(-140px), -10px);

        transform: rotate(180deg);
      }
    }
    &:nth-child(15) {
      grid-area: 7 / 4 / 8 / 5;
    }
    &:nth-child(16) {
      grid-area: 8 / 3 / 9 / 4;
    }
    &:nth-child(17) {
      grid-area: 8 / 5 / 9 / 6;
    }
    &:nth-child(18) {
      grid-area: 9 / 2 / 10 / 3;
    }
    &:nth-child(19) {
      grid-area: 9 / 4 / 10 / 5;
    }
    &:nth-child(20) {
      grid-area: 9 / 6 / 10 / 7;
    }
    &:nth-child(21) {
      grid-area: 10 / 4 / 11 / 5;
    }
    &:nth-child(22) {
      grid-area: 10 / 6 / 11 / 7;
    }
    &:nth-child(23) {
      grid-area: 10 / 8 / 11 / 9;
    }
  }
  &__name {
    font-size: 13px;

    margin-bottom: 13px;

    color: $light-grey;
  }
  &__number {
    font-size: 13px;
  }
}
