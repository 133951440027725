@import "../../scss/abstracts/variables";
@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/functions";

.services-list {
  &__list {
    display: flex;
    flex-direction: column;

    overflow: hidden;
  }
  &__item {
    &:not(:first-child) {
      margin-top: -1px;
    }
    &:hover {
      z-index: 100;

      .services-list__name .line-wrapper .line .word {
        color: $white;
      }

      .services-list__time .line-wrapper .line .word {
        color: $white;
      }

      .services-list__text .line-wrapper .line .word {
        color: $white;
      }
      .services-list__item-wrapper {
        border-color: $white;
      }
    }
  }
  &__item-wrapper {
    display: flex;

    padding: clamp(10px, size-desktop(50px), 50px) 0;

    transition: border 0.3s;

    overflow: hidden;

    color: $light-grey;
    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
  }
  &__name {
    font-size: clamp(24px, size-desktop(32px), 32px);

    max-width: 40%;
    width: 100%;

    transition: color 0.3s;
  }
  &__time {
    font-size: 18px;

    max-width: 20%;
    width: 100%;

    transition: color 0.3s;
  }
  &__info {
    max-width: 40%;
    width: 100%;
    overflow: hidden;
  }
  &__info--open & {
    &__button {
      display: none;
    }
    &__info-img {
      display: block;
    }
    &__info-button {
      display: block;
    }
  }
  &__text {
    font-size: 18px;

    margin-bottom: 20px;

    transition: color 0.3s;
  }
  &__button {
    font-size: 18px;

    padding: 0 6px;
    transition: color 0.3s;
    margin-bottom: 5px;

    color: $white;

    &:hover {
      color: $light-grey;
    }
  }
  &__info-img {
    max-width: 680px;
    width: 100%;
    height: 242px;
    margin-top: 20px;
    margin-bottom: 20px;

    display: none;

    object-fit: cover;
  }
  &__info-button {
    display: none;
  }
}
