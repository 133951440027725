@mixin hidden-element {
  position: absolute;
  clip: rect(0 0 0 0);

  width: rem(1px);
  height: rem(1px);
  margin: rem(-1px);
}

@mixin background {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@mixin position {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin margin-left40 {
  margin-left: 40%;
}
@mixin scroll-style {
  &::-webkit-scrollbar {
    width: 18px;
    max-height: 20px !important;
  }
  &::-webkit-scrollbar-track {
    background-color: $white;
    border: 1px solid $white;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $dark-grey;
    border-left: 10px solid white;
  }
  &::-webkit-scrollbar-button {
    background-color: $white;

    height: 0px;
  }
}

//ШРИФТ

@mixin light {
  font-family: "NeueHaasDisplayLight", sans-serif;
  font-weight: 300;
}

@mixin roman {
  font-family: "NeueHaasDisplayRoman", sans-serif;
  font-weight: 400;
}
