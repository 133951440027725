@import "../../scss/abstracts/variables";
@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/functions";

.privacy {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  z-index: 156;
  background-color: $dark-grey;
  background-image: url("../../../../public/images/bg2.png");
  flex-direction: column;
  transform: translateY(-100%);

  .container {
    display: flex;
    flex-direction: column;
  }
  &__wrapper {
    width: 100%;
    height: 100vh;
    z-index: 100;
    background-color: $super-light-grey;
    background-image: url("../../../../public/images/bg.png");

    transform: translateX(100%);
    justify-content: center;
    display: flex;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 32px 0;
  }

  &__content {
    display: flex;
    flex-direction: column;

    overflow: hidden;

    margin-top: auto;
    margin-bottom: auto;
    max-width: 60%;
    width: 100%;
    margin-left: auto;
  }
  &__close {
    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
  }

  .close-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40px;
    height: 40px;
    position: relative;
    overflow: hidden;
    > div {
      position: relative;
    }
    &-block {
      width: 40px;
      height: 20px;
      position: relative;
      overflow: hidden;
      &:before,
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: calc(55% - 3px);
        display: block;
        width: 3px;
        height: 25px;
        transform-origin: bottom center;
        background: $grey;
        transition: all ease-out 280ms;
      }
      &:last-of-type {
        transform: rotate(180deg);
      }
    }
    .in {
      .close-button-block {
        &:before {
          transition-delay: 280ms;
          transform: translateX(22px) translateY(-22px) rotate(45deg);
        }
        &:after {
          transition-delay: 280ms;
          transform: translateX(-22px) translateY(-22px) rotate(-45deg);
        }
      }
    }
    .out {
      position: absolute;
      top: 0;
      left: 0;
      .close-button-block {
        &:before {
          transform: translateX(-5px) translateY(5px) rotate(45deg);
        }
        &:after {
          transform: translateX(5px) translateY(5px) rotate(-45deg);
        }
      }
    }
    &:hover {
      .in {
        .close-button-block {
          &:before {
            transform: translateX(-5px) translateY(5px) rotate(45deg);
          }
          &:after {
            transform: translateX(5px) translateY(5px) rotate(-45deg);
          }
        }
      }
      .out {
        .close-button-block {
          &:before {
            transform: translateX(-20px) translateY(20px) rotate(45deg);
          }
          &:after {
            transform: translateX(20px) translateY(20px) rotate(-45deg);
          }
        }
      }
    }
  }
  &__logo {
    opacity: 0;
  }
  &__title {
    margin-bottom: size-desktop(70px);
    border-bottom: $contour-grey;

    transform: translateX(-100%);
  }
  &__text {
    font-size: clamp(18px, size-desktop(32px), 32px);
    color: $grey;
    &:not(:last-child) {
      margin-bottom: size-desktop(30px);
    }
  }
  .line {
    transform: translateY(300%) rotate(10deg);
  }
}
